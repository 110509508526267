<template>
	<div>
		<v-card dense>
			<v-container>
				<v-card-title>Información de Pallet</v-card-title>
				<v-row style="margin: auto">
					<v-col cols="12">
						<s-scanner-qr
							:config="configScann"
							autofocus
							return-object
							@onValue="onValue($event)"
							:readonly="false"
						></s-scanner-qr>
					</v-col>
				</v-row>
				<v-divider></v-divider>
				<v-row>
					<v-col cols="12">
						<v-data-table
							:headers="headers"
							:items="record"
							dense
								:items-per-page="-1"
								hide-default-footer
						>
							<template v-slot:item.detalle="{item}">
								<v-btn
									x-small
									color="success"
									@click="viewDetail(item)"
								>
									<i class="fas fa-info"></i>
								</v-btn>
							</template>
						</v-data-table>
					</v-col>
				</v-row>
			</v-container>
		</v-card>

		<v-dialog
			v-model="dialogDetail"
			persistent
			width="700"
		>
			<v-card>
				<s-toolbar
					label="Detalles"
					close
					@close="closeDetail()"
				></s-toolbar>
				<v-container>
					<v-row>
						<v-col cols="12">
							<v-data-table
								:headers="headers"
								:items="details"
								dense
								:items-per-page="-1"
								hide-default-footer
							>
								<!-- <template v-slot:item.detalle="{item}">
									<v-btn
										x-small
										color="success"
										@click="viewDetail(item)"
									>
										<i class="fas fa-info"></i>
									</v-btn>
								</template> -->
							</v-data-table>
						</v-col>
					</v-row>
				</v-container>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
	import _sPalletSend from "@/services/FrozenProduction/FrzLotProductionSelectionPalletSendService.js"

	export default {
		data() {
			return {
				dialogDetail: false,
				configScann: {},
				record: [],
				details: [],
				headers: [
					{text: "ID", value:"LlpID"},
					{text: "Detalle", value:"detalle"},
					{text: "N° Jabas", value:"LlpQuantityJaba"},
					{text: "Categoría", value:"TypeFrozenCategoryName"},
					{text: "Tipo Selección", value:"TypeSelectionFrozenName"},
					{text: "Tipo Cultivo", value:"TypeCropName"},
					{text: "Cultivo", value:"TypeCultiveName"},
					{text: "Variedad", value:"VrtName"},
					/* {text: "Fec. Partición", value:"LlpDatePartition"}, */
				]
			}
		},

		methods: {
			viewDetail(item)
			{
				this.dialogDetail = true;
				_sPalletSend
				.trackinginformation(item.LlpID, this.$fun.getUserID())
				.then(resp => {
					this.details = resp.data;
				})
			},

			closeDetail()
			{
				this.dialogDetail = false;
			},

			onValue(val) {
                
				_sPalletSend
				.trackinginformation(val, this.$fun.getUserID())
				.then(resp => {
					this.record = resp.data;
                    console.log("🚀 ~ file: PalletInformation.vue ~ line 41 ~ onValue ~ this.record", this.record)
				})
			},

			
		},
	}
</script>
